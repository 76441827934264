












import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import PermissionsForm from '@/components/forms/role/PermissionsForm.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerRolesModule from '@/store/modules/manager/roles'
import { RoleResource } from '@/store/types'

@Component({
  components: { PermissionsForm },
})
export default class RolesNew extends Mixins(NotifyMixin) {
  private get breadcrumbs () {
    return [
      { name: 'Группы прав доступа', path: '/manager/users/roles' },
    ]
  }

  private created () {
    ManagerRolesModule.fetchPermissions()
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.replace({ name: 'manager.users.roles' })
      })
  }

  private handleSuccess (role: RoleResource) {
    this.$router.push({
      name: 'manager.users.roles.item',
      params: { roleUUID: role.uuid },
    })
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Создание группы прав',
    }
  }
}
